.otp-input input {
  width: 44px !important;
  height: 44px !important;
}

@media screen and (min-width: 390px) {
  .otp-input input {
    width: 50px !important;
    height: 50px !important;
  }
}

@media screen and (min-width: 440px) {
  .otp-input input {
    width: 60px !important;
    height: 60px !important;
  }
}

@media screen and (min-width: 760px) {
  .otp-input input {
    width: 50px !important;
    height: 50px !important;
  }
}

@media screen and (min-width: 1120px) {
  .otp-input input {
    width: 60px !important;
    height: 60px !important;
  }
}

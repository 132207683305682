.Project-container {
  @apply grid bg-white grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center;
}
.Project-cards {
  @apply w-[280px] p-2 relative  shadow-lg m-4 bg-white;
}
.Project-img_div {
  @apply shadow-md mx-auto my-2;
}
.Project-header {
  @apply my-4 px-2 pt-4 border-t-2 border-[#f47f30];
}
.Project-header_lead {
  @apply flex justify-between items-end mb-2;
}
.Project-actions {
  @apply flex gap-3 justify-end items-center mt-2;
}
.Project-actions_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  display: none;
  /* z-index; */
  background-color: rgba(97, 97, 97, 0.5);
}

.Project-cards:hover .Project-actions_div {
  display: flex;
  cursor: pointer;
}

.Display input[type='search'] {
  @apply pl-10;
}

.ReactTags__tagInput > input {
  @apply block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#f47f30] dark:focus:border-[#f47f30] focus:outline-none focus:ring-0 focus:border-[#f47f30];
}

.ReactTags__selected {
  @apply flex gap-2 flex-wrap;
}

.ReactTags__tag {
  @apply bg-blue-100 px-2 py-1 border rounded-full text-xs flex gap-x-2;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.classis {
  background-color: yellow;
  color: red;
  padding: 30px;
  display: inline-block;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: -6px;
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #f47f30;
}
input:focus + .slider {
  box-shadow: 0 0 1px #f47f30;
}
input:checked + .slider:before {
  /* -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px); */
  transform: translate(12px, -8px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.Field-Card {
  @apply relative mb-6 shadow-md border rounded-md p-4 bg-white;
  /* z-index: 1000; */
  /* z-index: 5; */
}

.Field-Card::after {
  content: '';
  width: 100%;
  height: 100%;
  /* background-color: #f47f30; */
  position: absolute;
  /* z-index: 10; */
  border-radius: 6px;
  top: 10px;
  left: -10px;
  z-index: -10;
  opacity: 0.4;
}
